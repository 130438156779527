const theme = {
  global: {
    font: {
      family: 'poppins',
      size: '14px',
    },
    colors: {
      text: '#000',
      brand: '#2c63ca',
      content: '#fff',
      background: '#f3f4f8',
      border: '#999',
      alert: '#ff4040'
    },
    focus: {
      border: {
        color: "none",
      }
    }
  },
  button: {
    primary: {
      extend: 'color: white;',
    },
    border: {
      width: '5px',
      color: '#999',
      radius: '0',
    },
    padding: {
      vertical: '3rem',
      horizontal: '3rem',
    },
  },
  formField: {
    border: {
        side: 'none',
    },
  },
  radioButton: {
      size: '20px',
  },
  /*calendar: {
    day: {
      extend: ({ date, day, isInRange, isSelected }) => `
        color: ${isSelected ? 'red' : 'black'};
        `,
      },
  }*/
};

export default theme;




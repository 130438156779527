import React, {Component, Fragment} from "react";
import styled from "styled-components";

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Invoice extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.label !== undefined){
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = {allowdisp: false};
    }
    
    // this.state = {
    //     customername: "Adeline Devina",
    //     customerphone: "08112345678",
    //     customeraddress: "Jl. Wisma Sari XVI no. 9, Kec. Ngaliyan, Kota Semarang, Jawa Tengah",
    //     invoice_no: "S-TP-00000232",
    //     mp_invoice: "INV/20210713/MPL/1404828096",
    //     invoice_date: "2021-01-15 23:00:00",
    //     sales_channel: "WhatsApp",
    //     gross_total: 1400000,
    //     shipping_cost: 200000,
    //     discount: 0,
    //     grand_total: 1600000,
    //     items: [
    //       {
    //         qty: 2,
    //         name: "Produk A",
    //         price: 100000,
    //       },
    //       {
    //         qty: 3,
    //         name: "Produk B",
    //         price: 200000,
    //       },
    //       {
    //         qty: 1,
    //         name: "Produk C",
    //         price: 600000,
    //       },
    //     ],
    // }
    
    //disable this after layouting
    //this.state.allowdisp = true;
  }
  
  renderItems(){
    let disp = this.state.items.map((dt) => {
      return (
        <TableRow>
          <TableCol className="tname">{dt.name} {dt.additional.length > 0 ? (<><br/><em>ADDITIONAL: {dt.additional}</em></>) : (<></>)}</TableCol>
          <TableCol className="align-right tqty">{dt.qty}</TableCol>
          <TableCol className="align-right tprice">IDR {numberFormat(dt.price)}</TableCol>
          <TableCol className="align-right ttotal">IDR {numberFormat(dt.price * dt.qty)}</TableCol>
        </TableRow>
      )
    });
    
    return disp;
  }
  
  convertDate(datestr){
    let date_start = new Date(datestr.substr(0, 10));
    
    let start_year = date_start.getFullYear();
    let start_month = date_start.getMonth();
    let start_date = date_start.getDate();
    
    let returnstr = start_date.toString().padStart(2, "0") + " " + monthNames[start_month] + " " + start_year;
    
    return returnstr;
  }
  
  render() {
    return (
      <OuterWrap>
      <Wrapper>
        {this.state.allowdisp &&
        <>
        <Header>
          <HeaderLeft>
            <HeaderLogo src="logo-upspace.png" />
          </HeaderLeft>
          <HeaderRight>
            <HeaderInvoiceWrap>
              <HeaderInvoice>INVOICE</HeaderInvoice>
              <HeaderInvoiceNo>{this.state.invoice_no}</HeaderInvoiceNo>
              {this.state.mp_invoice.length > 0 &&
                <HeaderInvoiceMP>{this.state.mp_invoice}</HeaderInvoiceMP>
              }
            </HeaderInvoiceWrap>
          </HeaderRight>
        </Header>
        <Recipient>
          <RecipientRow>
            <RecipientLeft>Order Date </RecipientLeft>
            <RecipientRight>{this.convertDate(this.state.invoice_date)}</RecipientRight>
          </RecipientRow>
          <RecipientRow>
            <RecipientLeft>Address </RecipientLeft>
            <RecipientRight>{this.state.sales_channel}</RecipientRight>
          </RecipientRow>
        </Recipient>
        <TableContents>
          <TableRow>
            <TableHeader>ITEM DESCRIPTION</TableHeader>
            <TableHeader>QTY</TableHeader>
            <TableHeader>PRICE</TableHeader>
            <TableHeader>TOTAL</TableHeader>
          </TableRow>
          <TableRow>
            <TableCol /><TableCol /><TableCol /><TableCol />
          </TableRow>
          {this.renderItems()}
        </TableContents>
        <Totals>
          <TotalRow>
            <TotalLeft></TotalLeft>
            <TotalMid>Total</TotalMid>
            <TotalRight>IDR {numberFormat(this.state.gross_total)}</TotalRight>
          </TotalRow>
          <TotalRow>
            <TotalLeft></TotalLeft>
            <TotalMid>Shipping Cost {this.state.hasadditional ? "and Additionals" : ""}</TotalMid>
            <TotalRight>IDR {numberFormat(this.state.shipping_cost)}</TotalRight>
          </TotalRow>
          {this.state.discount > 0 &&
          <TotalRow>
            <TotalLeft></TotalLeft>
            <TotalMid>Discount</TotalMid>
            <TotalRight>IDR {numberFormat(this.state.discount)}</TotalRight>
          </TotalRow>
          }
          <TotalRow className="grandtotalrow">
            <TotalLeft></TotalLeft>
            <TotalMid className="grandtotalcol">GRAND TOTAL</TotalMid>
            <TotalRight className="grandtotalcol">IDR {numberFormat(this.state.grand_total)}</TotalRight>
          </TotalRow>
        </Totals>
        <Footer>
          <FooterRow>
            <Shipping>
              <BoldText className="shippingaddress">SHIPPING ADDRESS</BoldText>
              <RegularText>{this.state.customername}</RegularText>
              <RegularText>{this.state.customerphone}</RegularText>
              <RegularText>{this.state.customeraddress}</RegularText>
              <RegularText>{this.state.customeraddress2}</RegularText>
            </Shipping>
          </FooterRow>
          <FooterRow>
            <FooterLeft>
              <BoldText>CUSTOMER SERVICE</BoldText>
              <RegularText>+62 816 667 679</RegularText>
            </FooterLeft>
            <FooterMid>
              <BoldText>CUSTOMER CARE</BoldText>
              <RegularText>+62 822 25501551</RegularText>
            </FooterMid>
            <FooterRight>
              <RegularText>ig: @upspace.id</RegularText>
              <RegularText>www.upspace.id</RegularText>
            </FooterRight>
          </FooterRow>
        </Footer>
        </>
        }
      </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  
`;

const Wrapper = styled.div`
  display:block;
  position:relative;
  /*width: 14cm;*/
  width: 12.5cm;
  height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;
  border: 2px solid black;
  
  padding:5mm;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1em;
  
  /*
  @page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display:block;
  position:relative;
  width: 100%;
  border-bottom: 1px solid black;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const HeaderLeft = styled.div`
  display:block;
  position:relative;
  width: 30%;
  float:left;
  
  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display:block;
  position:relative;
  width: 70%;
  margin:auto;
  margin-bottom: 1mm;
`;

const HeaderRight = styled.div`
  display:block;
  position:relative;
  width: 65%;
  height:20mm;
  float:left;
`;

const HeaderInvoiceWrap = styled.div`
  display:block;
  position:absolute;
  width: 100%;
  bottom: 0;
`;

const HeaderText = styled.p`
  text-align:center;
  margin-bottom: 1mm;
`;

const HeaderInvoice = styled.h1`
  text-align:right;
  margin-bottom: 4mm;
  font-size: 3em;
`;

const HeaderInvoiceNo = styled.div`
  font-size:1.2em;
  text-align:right;
  margin-bottom: 1mm;
`;

const HeaderInvoiceMP = styled.div`
  font-size:1em;
  text-align:right;
  margin-bottom: 1mm;
`;

const Recipient = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:3mm;
  padding-bottom: 5mm;
`;

const RecipientRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const RecipientLeft = styled.div`
  display:block;
  position:relative;
  width: 13%;
  float:left;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const RecipientRightLarge = styled.div`
  display:block;
  position:relative;
  width: 81%;
  padding-left:5%;
  float:left;
  font-size:1.3em;
  font-weight:bold;
`;

const RecipientRight = styled.div`
  display:block;
  position:relative;
  width: 81%;
  padding-left:5%;
  float:left;
`;

const TextRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:8mm;
`;

const TotalRow = styled .div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom: 2mm;
  
  &.grandtotalrow {
    padding-top:5mm; 
  }
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const TotalLeft = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  height: 1mm;
`;

const TotalMid = styled.div`
  display:block;
  position:relative;
  width: 25%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;


const TotalRight = styled.div`
  text-align: right;
  display:block;
  position:relative;
  width: 24%;
  padding-right:1%;
  float:left;
  
  &.grandtotalcol {
    font-size: 1.2em;
    font-weight:bold;
  }
`;
  
const TableContents = styled.table`
  display:table;
  position:relative;
  width: 100%;
  border-spacing: 0;
  margin-top: 1mm;
  font-size:11px;
`;

const TableRow = styled.tr`
  display:table-row;
  position:relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display:table-cell;
  position:relative;
  background: black;
  color: white;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
`;

const TableCol = styled.td`
  display: table-cell;
  position:relative;
  padding: 1%;
  vertical-align:top;
  font-size: 1em;
  
  &.align-right {
    text-align: right;
  }
  &.tname {
    width: 40%; 
  }
  &.tqty {
    width: 10%; 
  }
  &.tprice {
    width: 25%; 
  }
  &.ttotal {
    width: 25%; 
  }
`;

const TableBoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
  
  &.shippingaddress {
    margin-bottom: 3mm; 
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Shipping = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:5mm;
  border-bottom: 1px solid black;
`;

const Footer = styled.div`
  display:block;
  position:absolute;
  bottom: 0;
  width: 92%;
  padding-bottom:2mm;
`;

const FooterRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  
  padding-top:2mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const FooterLeft = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterMid = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

const FooterRight = styled.div`
  display:block;
  position:relative;
  width: 33%;
  float:left;
  
  text-align:center;
`;

export default Invoice;
import React, {Component, Fragment} from "react";
import styled from "styled-components";

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function numberFormat(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class PrintSuratJalanPro extends Component {
  constructor(props) {
    super(props);
    
    if(this.props.label !== undefined){
      this.state = this.props.label;
      this.state.allowdisp = true;
    } else {
      this.state = {allowdisp: false};
    }
    
    // this.state = {
    //     no_sj: "SJ-00000001",
    //     sj_date: "2021-01-15 23:00:00",
    //     courier: "JNE",
    //     total_invoice: 10,
    //     total_barang: 11,
    //     total_koli: 12,
    //     items: [
    //       {
    //         name: "Adeline",
    //         city: "Jakarta",
    //         resi: "100000",
    //         barang: 5,
    //         koli: 2,
    //       },
    //     ],
    // }
    
    //disable this after layouting
    // this.state.allowdisp = true;
  }
  
  renderItems(){
    let disp = this.state.items.map((dt) => {
      return (
        <TableRow>
          <TableCol className="tno">{dt.no}</TableCol>
          <TableCol className="tname">{dt.name} {dt.city}</TableCol>
          <TableCol className="align-center tkoli">{dt.koli}</TableCol>
        </TableRow>
      )
    });
    
    return disp;
  }
  
  convertDate(datestr){
    let date_start = new Date(datestr.substr(0, 10));
    
    let start_year = date_start.getFullYear();
    let start_month = date_start.getMonth();
    let start_date = date_start.getDate();
    
    let returnstr = start_date.toString().padStart(2, "0") + " " + monthNames[start_month] + " " + start_year;
    
    return returnstr;
  }
  
  render() {
    return (
      <OuterWrap>
      <Wrapper>
        {this.state.allowdisp &&
        <>
        <Header>
          <HeaderLeft>
            <HeaderLogo src="logo-upspace.png" />
          </HeaderLeft>
          <HeaderRight>
            <HeaderInvoiceWrap>
              <HeaderInvoice>SURAT JALAN PRODUKSI</HeaderInvoice>
              <HeaderInvoiceNo>{this.state.no_sj}</HeaderInvoiceNo>
            </HeaderInvoiceWrap>
          </HeaderRight>
        </Header>
        <Recipient>
          <RecipientRow>
            <RecipientLeft>Shipping Date </RecipientLeft>
            <RecipientRight>{this.convertDate(this.state.sj_date)}</RecipientRight>
          </RecipientRow>
          <RecipientRow>
            <RecipientLeft>Courier </RecipientLeft>
            <RecipientRight>{this.state.courier}</RecipientRight>
          </RecipientRow>
        </Recipient>
        <TableContents>
          <TableRow>
            <TableHeader>No.</TableHeader>
            <TableHeader>Nama</TableHeader>
            <TableHeader className="align-center">Total Koli</TableHeader>
          </TableRow>
          {this.renderItems()}
        </TableContents>
        <Footer>
          <FooterRow>
            <Totals>
              <TotalRow>
                <TotalLeft>Total DO</TotalLeft>
                <TotalRight>{this.state.total_invoice}</TotalRight>
              </TotalRow>
              { /*
              <TotalRow>
                <TotalLeft>Total Barang</TotalLeft>
                <TotalRight>{this.state.total_barang}</TotalRight>
              </TotalRow>
              */ }
              <TotalRow>
                <TotalLeft>Total Koli</TotalLeft>
                <TotalRight>{this.state.total_koli}</TotalRight>
              </TotalRow>
            </Totals>
          </FooterRow>
          <FooterRow>
            <FooterLeft>
              <RegularText>Driver,</RegularText>
            </FooterLeft>
            <FooterRight>
              <RegularText>Penerima,</RegularText>
            </FooterRight>
          </FooterRow>
          <PageFoot>HALAMAN {this.state.page} DARI {this.state.totalpage}</PageFoot>
        </Footer>
        </>
        }
      </Wrapper>
      </OuterWrap>
    );
  }
}

const OuterWrap = styled.div`
  
`;

const Wrapper = styled.div`
  display:block;
  position:relative;
  /*width: 14cm;*/
  width: 12.5cm;
  height: 19cm;
  margin-top: 1cm;
  margin-left: 0.5cm;
  border: 2px solid black;
  
  padding:5mm;
  
  font-family: Helvetica, Arial, sans-serif;
  font-size: 11px;
  line-height: 1em;
  
  /*@page {
    size: landscape;
  }*/
`;

const Header = styled.div`
  display:block;
  position:relative;
  width: 100%;
  border-bottom: 1px solid black;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const HeaderLeft = styled.div`
  display:block;
  position:relative;
  width: 23%;
  float:left;
  
  border-right: 1px solid black;
`;

const HeaderLogo = styled.img`
  display:block;
  position:relative;
  width: 60%;
  margin:auto;
  margin-bottom: 1mm;
`;

const HeaderRight = styled.div`
  display:block;
  position:relative;
  width: 72%;
  height:15mm;
  float:left;
`;

const HeaderInvoiceWrap = styled.div`
  display:block;
  position:absolute;
  width: 100%;
  bottom: 0;
`;

const HeaderText = styled.p`
  text-align:center;
  margin-bottom: 1mm;
`;

const HeaderInvoice = styled.h1`
  text-align:right;
  margin-bottom: 4mm;
  font-size: 2em;
`;

const HeaderInvoiceNo = styled.div`
  font-size:1.2em;
  text-align:right;
  margin-bottom: 1mm;
`;

const HeaderInvoiceMP = styled.div`
  font-size:1em;
  text-align:right;
  margin-bottom: 1mm;
`;

const Recipient = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-top:3mm;
  padding-bottom: 5mm;
`;

const RecipientRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:1mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const RecipientLeft = styled.div`
  display:block;
  position:relative;
  width: 20%;
  float:left;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const RecipientRightLarge = styled.div`
  display:block;
  position:relative;
  width: 70%;
  padding-left:5%;
  float:left;
  font-size:1.3em;
  font-weight:bold;
`;

const RecipientRight = styled.div`
  display:block;
  position:relative;
  width: 70%;
  padding-left:5%;
  float:left;
`;

const TextRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  font-weight: bold;
`;

const Totals = styled.div`
  display:block;
  position:relative;
  width: 100%;
  border-bottom: 1px solid black;
`;

const TotalRow = styled .div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom: 2mm;
  
  &.grandtotalrow {
    padding-top:5mm; 
  }
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const TotalLeft = styled.div`
  display:block;
  position:relative;
  width: 25%;
  float:left;
  font-size:1.2em;
  
  &:after {
    position: absolute;
    content: ":";
    right:0;
  }
`;

const TotalRight = styled.div`
  display:block;
  position:relative;
  width: 20%;
  padding-left:1%;
  float:left;
  font-size:1.2em;
`;
  
const TableContents = styled.table`
  display:table;
  position:relative;
  width: 100%;
  border-spacing: 0;
  font-size: 10px;
`;

const TableRow = styled.tr`
  display:table-row;
  position:relative;
  width: 100%;
`;

const TableHeader = styled.th`
  display:table-cell;
  position:relative;
  margin-bottom: 2mm;
  font-size: 1.2em;
  padding: 1%;
  text-align: left;
  
  &.align-center {
    text-align: center;
  }
`;

const TableCol = styled.td`
  display: table-cell;
  position:relative;
  padding: 0.5mm 1%;
  vertical-align:top;
  font-size: 1em;
  
  &.align-center {
    text-align: center;
  }
  &.tno {
    width: 5%; 
  }
  &.tname {
    width: 70%; 
    white-space: pre-wrap;
  }
  &.tkoli {
    width: 10%; 
  }
`;

const TableBoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  font-size: 0.9em !important;
`;

const TableRegularText = styled.div`
  padding: 0;
  margin: 2mm 1mm;
  font-size: 0.9em !important;
`;

const BoldText = styled.div`
  font-weight:bold;
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
  
  &.shippingaddress {
    margin-bottom: 3mm; 
  }
`;

const RegularText = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 1mm;
`;

const Shipping = styled.div`
  display:block;
  position:relative;
  width: 100%;
  padding-bottom:5mm;
  border-bottom: 1px solid black;
`;

const Footer = styled.div`
  display:block;
  position:absolute;
  bottom: 0;
  width: 92%;
  padding-bottom:2mm;
`;

const FooterRow = styled.div`
  display:block;
  position:relative;
  width: 100%;
  
  padding-top:5mm;
  
  /*CLEARFIX*/
  overflow: auto;
  &:after {
      content: "";
      clear:both;
      display:table;
  }
`;

const FooterLeft = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  font-size: 1.2em;
  padding-bottom:25mm;
  
  text-align:left;
`;

const FooterRight = styled.div`
  display:block;
  position:relative;
  width: 50%;
  float:left;
  font-size: 1.2em;
  padding-bottom:25mm;
  
  text-align:right;
`;

const PageFoot = styled.div`
  display:block;
  position: absolute;
  bottom: 5mm;
  width:100%;
  text-align:center;
  font-size:1.2em;
`;

export default PrintSuratJalanPro;